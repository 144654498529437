<template>
  <div class="all-transactions-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          class="mt-4"
          ref="fee-transactions-smart-list"
          :endpoint="transactionsEndpoint"
          :filter-fields="filterFields"
          paginated
        >
          <template v-slot:list-item="{ item: transaction }">
            <v-list-item
              :to="{
                name: 'StudentFeeDetails',
                params: { studentId: transaction.student.id },
              }"
            >
              <v-list-item-content>
                <v-list-item-title>{{
                  transaction.student.full_name
                }} <span class="text-caption">({{transaction.student.room.sections}})</span></v-list-item-title>

                <v-list-item-subtitle
                v-html="TextHelper.linkify(transaction.description)"
                >
                </v-list-item-subtitle>

                <v-list-item-subtitle v-if="transaction.transaction_mode"
                  >Mode of Payment:
                  {{ getPaymentMode(transaction) }}</v-list-item-subtitle
                >
                <v-list-item-subtitle class="text-caption mt-1">{{
                  moment(transaction.transaction_date).format(
                    "lll"
                  )
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-self-center"
                ><p class="ma-0 font-weight-bold text-h6 primary--text">
                  ₹ {{ transaction.paid_amount }}
                </p></v-list-item-action
              >
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </template>
          <template v-slot:paginated-no-items-found
            >No transactions found</template
          >
        </intract-smart-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextHelper from '@utils/text_helper';
import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
import moment from "moment";
import { mapGetters } from "vuex";
import EventBus from "@utils/event_bus";
export default {
  name: "AllTransactions",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
  },
  data() {
    return {
      TextHelper, 
      moment,
    };
  },
  computed: {
    ...mapGetters(["allRooms"]),
    transactionsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.feeTransactionViewSet, [
        "ordering=-transaction_date",
      ]);
    },
    filterFields() {
      return {
        classroom: {
          model: null,
          multiple: true,
          items: this.allRooms,
          label: "Filter by Classroom",
          itemValue: "id",
          itemText: "sections",
          param: "student__room__in",
        },
        transaction_mode: {
          model: null,
          items: this.paymentModes,
          multiple: true,
          label: "Filter by Transaction Mode",
          itemValue: "value",
          itemText: "title",
          param: "transaction_mode__in",
        },
      };
    },
    paymentModes() {
      return [
        { title: "Cash", value: 1 },
        { title: "Cheque", value: 2 },
        { title: "Bank Transfer", value: 3 },
        { title: "Online Payment", value: 4 },
        { title: "UPI", value: 5 },
      ];
    },
  },
  methods: {
    async onRefresh() {
      var smartList = this.$refs["fee-transactions-smart-list"];
      if (smartList) smartList.getItems();
    },

    getPaymentMode(transaction) {
      if (!transaction.transaction_mode) return "";
      return this.paymentModes.find(
        (m) => m.value == transaction.transaction_mode
      ).title;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "StudentFeeDetails") {
      EventBus.$emit("keep_alive__destroy_view", "AllTransactions");
    }
    next();
  },
};
</script>